<template>
  <div class="root_cont blue_bg">
    <div class="container">
      <div class="carousel_cont" ref="carousel_cont">
        <div class="carousel_elements_cont">
          <div class="carousel_elements" ref="carousel_elements">
            <div class="carousel_element">
              <img
                :src="
                  $lang === 'hr'
                    ? '/static/documents/ReportExtractHR1.jpg'
                    : '/static/documents/ReportExtractEng1.jpg'
                "
                alt=""
              />
            </div>
            <div class="carousel_element">
              <img
                :src="
                  $lang === 'hr'
                    ? '/static/documents/ReportExtractHR2.jpg'
                    : '/static/documents/ReportExtractEng2.jpg'
                "
                alt=""
              />
            </div>
            <div class="carousel_element">
              <div class="video-cont">
                <div>
                  <h2 class="fw-400">
                    <span v-html="t.what_to_expect"></span>
                  </h2>
                </div>
                <div>
                  <iframe
                    ref="yt_frame"
                    width="100%"
                    allowfullscreen
                    :src="
                      'https://www.youtube.com/embed/' +
                        ($lang === 'hr'
                          ? 't9gfdJrVv5s'
                          : 'kGSupjcfN1Y') +
                        '?enablejsapi=1&rel=0'
                    "
                  ></iframe>
                </div>
                <div>
                  <div>
                    <!--                  <h3 class="mb-3" @click="playVideo">Click to play</h3>-->
                    <button
                      @click="toRegistration()"
                      class="btn btn-lg button_slide_light_green"
                    >
                      <span v-html="t.REGISTER"></span>
                    </button>
                    <br />
                    <span ref="share_cont"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div v-if="left_arrow_visible" class="arrow left">
            <span @click="carousel.left()">
              <svg-arrow-normal></svg-arrow-normal>
            </span>
          </div>
          <div v-if="right_arrow_visible" class="arrow right">
            <span @click="carousel.right()">
              <svg-arrow-normal></svg-arrow-normal>
            </span>
          </div>
        </div>
      </div>
      <div class="carousel_bottom">
        <div class="navigation-circles">
          <span class="navigation-circle" @click="carousel.moveTo(0)"></span>
          <span class="navigation-circle" @click="carousel.moveTo(1)"></span>
          <span class="navigation-circle" @click="carousel.moveTo(2)"></span>
        </div>
      </div>
      <div class="text-center">
        <button
          class="btn btn-lg button_slide_white mb-3"
          @click="$router.push({ name: 'journey_zero' })"
        >
          <span v-html="t.restart_journey"></span>
        </button>
        <br />
        <button
          class="btn btn-lg button_slide_white mb-5"
          @click="$router.push({ name: 'locksmith' })"
        >
          <span v-html="t.learn_more"></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ShareBtn from "../../components/Elements/ShareBtn";

const t =
  //<i18n>
{
  "en": {
    "what_to_expect": "What to expect...",
    "REGISTER": "REGISTER",
    "restart_journey": "RESTART JOURNEY",
    "learn_more": "LEARN MORE",
    "share": "SHARE"
  },
  "hr": {
    "what_to_expect": "Što očekivati...",
    "REGISTER": "REGISTRACIJA",
    "restart_journey": "PONOVI PUTOVANJE",
    "learn_more": "SAZNAJ VIŠE",
    "share": "PODIJELI"
  },
  "es": {
    "what_to_expect": "Qué esperar...",
    "REGISTER": "REGÍSTRESE",
    "restart_journey": "REINICIAR PASAJE",
    "learn_more": "MÁS INFORMACIÓN",
    "share": "COMPARTIR"
  }
}
//</i18n>

import { TweenMax, Draggable } from "gsap/all";

export default {
  name: "JourneyReportExtract",
  data() {
    return {
      t: t[this.$lang],
      carousel: null,
      carouse_item_width: 0,
      left_arrow_visible: true,
      right_arrow_visible: true
    };
  },
  mounted() {
    const vm = this;

    vm.initiateCarousel();
    // vm.initiateShareBtn();
  },
  beforeUnmount() {
    const vm = this;
    window.removeEventListener("resize", vm.carousel_calculateDims);
  },
  methods: {
    initiateCarousel() {
      const vm = this;
      vm.carousel = new (class {
        constructor() {
          const CI = this;
          CI.vm = vm;

          CI.x_pos = 0;
          CI.min_height = 400;
          CI.max_height = 700;
          CI.active_item = 0;
          CI.items_count = 3;

          CI.initial();
        }

        initial() {
          const CI = this;

          CI.calculateDims();

          // Draggable.create(CI.vm.$refs.carousel_elements, {
          //   type: "x",
          //   bounds: CI.vm.$refs.carousel_elements.parentNode,
          //   onClick: function() {},
          //   onDragEnd: function() {
          //     const d = this;
          //     const move_x = d.x - d.startX;
          //     if (Math.abs(move_x) > CI.cont_width / 5) {
          //       if (move_x > 0) CI.left();
          //       else CI.right();
          //     } else CI.update();
          //   }
          // });

          CI.update();
        }

        calculateDims() {
          const CI = this;
          console.log("calculateDims");

          const car_cont = CI.vm.$refs.carousel_cont;
          const y = car_cont.getBoundingClientRect().top + window.scrollY;
          let h = window.innerHeight - y - 60;
          h -= h > CI.max_height ? 150 : 0; // put two bottom buttons in viewport
          if (h < CI.min_height) h = CI.min_height;
          car_cont.style.height = h + "px";

          CI.cont_width = CI.vm.$refs.carousel_cont.getBoundingClientRect().width;
          CI.carousel_elements_full_width = CI.cont_width * 3;
          CI.slide_amount = CI.cont_width;
          CI.vm.carouse_item_width = CI.cont_width;

          CI.vm.$refs.carousel_elements.style.width =
            CI.carousel_elements_full_width + "px";

          CI.vm.$refs.carousel_elements
            .querySelectorAll(".carousel_element")
            .forEach(node => {
              node.style.width = CI.cont_width + "px";
            });

          const frame = CI.vm.$refs.carousel_elements.querySelector(
            ".carousel_element iframe"
          );
          let frame_w = CI.vm.$refs.carousel_elements
            .querySelector(".video-cont")
            .getBoundingClientRect().width;
          let frame_h = frame_w * (1080 / 1920);
          if (frame_w > h) {
            frame_h = h * 0.5;
            frame_w = frame_h * (1920 / 1080);
          }
          frame.style.height = frame_h + "px";
          frame.style.width = frame_w + "px";

          CI.min_x_pos = CI.cont_width - CI.carousel_elements_full_width;

          CI.update();
        }

        left() {
          const CI = this;

          if (CI.active_item === 0) CI.active_item = CI.items_count - 1;
          else CI.active_item--;
          CI.update();
        }

        right() {
          const CI = this;
          if (CI.active_item === CI.items_count - 1) CI.active_item = 0;
          else CI.active_item++;
          CI.update();
        }

        moveTo(el_num) {
          const CI = this;
          CI.active_item = el_num;
          CI.update();
        }

        update() {
          const CI = this;

          TweenMax.to(CI.vm.$refs.carousel_elements, 1, {
            x: -(CI.active_item * CI.slide_amount)
          });

          CI.setArrowVisibility();

          if (CI.active_item === 2) {
            // CI.vm.$refs.yt_frame.contentWindow.postMessage(
            //   '{"event":"command","func":"playVideo","args":""}',
            //   "*"
            // );
          } else {
            CI.vm.$refs.yt_frame.contentWindow.postMessage(
              '{"event":"command","func":"pauseVideo","args":""}',
              "*"
            );
          }

          CI.vm.$el
            .querySelectorAll(".navigation-circle")
            .forEach((node, i) => {
              node.style["background-color"] =
                i === CI.active_item ? "#fff" : null;
            });
        }

        setArrowVisibility() {
          const CI = this;
        }
      })();

      vm.carousel_calculateDims = vm.carousel.calculateDims.bind(vm.carousel);
      window.addEventListener("resize", vm.carousel_calculateDims);
    },
    initiateShareBtn() {
      const vm = this;

      const getMeta = function getMeta() {
        if (!this.meta_tags) {
          const getTagContent = selector =>
            document.querySelector(selector).content;

          this.meta_tags = {
            title_soc: getTagContent("meta[name='title']"),
            description_soc: getTagContent("meta[name='description']")
          };
        }
        return Promise.resolve(this.meta_tags);
      };

      const shareBtn = ShareBtn.call(
        {},
        { cont: vm.$refs.share_cont, getMeta, share_text: vm.t.share }
      );

      shareBtn.dom.share_btn.style("width", "100px")
        .classed("btn-lg mt-2", true)
        .style("padding", null)
        .style("width", "260px")

    },
    toRegistration() {
      this.$store.dispatch("auth/openAuth", 2);
    },
    playVideo() {
      const vm = this;

      vm.$refs.yt_frame.contentWindow.postMessage(
        '{"event":"command","func":"playVideo","args":[]}',
        "*"
      );
    }
  }
};
</script>

<style scoped>
.container {
}

img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

iframe {
  border: 0;
  outline: 0;
}

.carousel_bottom {
  height: 60px;
}

.navigation-circles {
  text-align: center;
  margin-top: 20px;
}

.navigation-circle {
  display: inline-block;
  margin: 0 6px;
  width: 20px;
  height: 20px;
  border: #fff 1px solid;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.carousel_cont {
  width: 100%;
  position: relative;
}

.carousel_elements_cont {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carousel_elements {
  height: 100%;
  padding: 0;
  display: flex;
}

.carousel_element {
  height: 100%;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.arrow {
  color: #6788b4;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 150px;
  height: 50px;

  opacity: 0.5;
  transition: opacity 0.3s;
}

.arrow:hover {
  opacity: 1;
}

.arrow.left {
  left: 0;
}
.arrow.right {
  right: 0;
}

.arrow.left svg {
  transform: rotate(90deg);
  position: relative;
  left: -120px;
}

.arrow.right svg {
  transform: rotate(-90deg);
  position: relative;
  right: -120px;
}

.btn.button_slide_light_green {
  background-color: #52b648;
}

.btn.button_slide_white {
  border-color: #fff;
}

.btn {
  color: #fff;
  padding-left: 30px;
  padding-right: 30px;
}

.button_slide_light_green {
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #fff;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.button_slide_light_green:hover {
  box-shadow: inset 400px 0 0 0 #fff;
  color: #52b648 !important;
}

.video-cont {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.video-cont > div:nth-child(1),
.video-cont > div:nth-child(3) {
  flex: 1;
}

.video-cont > div:nth-child(1) > * {
  margin-top: 50px;
}

.video-cont > div:nth-child(3) > * {
  margin-top: 17px;
}

@media (max-width: 576px) {
  .arrow {
    width: 75px;
    height: 25px;
  }
  .arrow.left svg {
    left: -41px;
  }

  .arrow.right svg {
    right: -41px;
  }
}
</style>
