import * as d3 from 'd3';

function ShareBtn({cont, getMeta, share_text}) {
  const CI = this;
  getMeta = getMeta.bind(CI)
  CI.dom = {}

  const share_btn = CI.dom.share_btn = d3.select(cont)
    .append("span")
    .attr("class", "btn")
    .style("position", "relative")
    .style("width", "100%")
    .style("padding", "10px 45px")
    .style("background-color", "rgb(4, 43, 93)")
    .style("border-color", "#fff")
    .style("color", "#fff")
    .style("font-weight", "600");

  CI.dom.share_icon = share_btn
    .append("svg")
    .style("width", "25px")
    .style("height", "25px")
    .style("margin-top", "1px")
    .style("position", "absolute")
    .style("left", "18px")
    .style("fill", "currentColor")

  CI.dom.share_icon
    .append("path")
    .attr(
      "d",
      "M367.8,309.4c-27.7,0-52.4,13.2-68.2,33.6l-132.3-73.9c3.1-8.9,4.8-18.5,4.8-28.4c0-10-1.7-19.5-4.9-28.5l132.2-73.8\n" +
      "\t\tc15.7,20.5,40.5,33.8,68.3,33.8c47.4,0,86.1-38.6,86.1-86.1S415.2,0,367.7,0s-86.1,38.6-86.1,86.1c0,10,1.7,19.6,4.9,28.5\n" +
      "\t\tl-132.1,73.8c-15.7-20.6-40.5-33.8-68.3-33.8C38.7,154.6,0,193.2,0,240.7s38.7,86.1,86.2,86.1c27.8,0,52.6-13.3,68.4-33.9\n" +
      "\t\tl132.2,73.9c-3.2,9-5,18.7-5,28.7c0,47.4,38.6,86.1,86.1,86.1S454,443,454,395.5S415.3,309.4,367.8,309.4z M367.8,27.1\n" +
      "\t\tc32.6,0,59.1,26.5,59.1,59.1s-26.5,59.1-59.1,59.1s-59.1-26.5-59.1-59.1S335.3,27.1,367.8,27.1z M86.2,299.8\n" +
      "\t\tc-32.6,0-59.1-26.5-59.1-59.1s26.5-59.1,59.1-59.1s59.1,26.5,59.1,59.1S118.7,299.8,86.2,299.8z M367.8,454.5\n" +
      "\t\tc-32.6,0-59.1-26.5-59.1-59.1s26.5-59.1,59.1-59.1s59.1,26.5,59.1,59.1S400.4,454.5,367.8,454.5z"
    )
    .attr("transform", "scale(.045)");

  CI.dom.share_text = share_btn
    .append("span")
    .attr("class", "share_text")
    .style("opacity", 1)
    .html(share_text);

  const icons = `
<a href="javascript:void(0)" data-w="fb" style="text-decoration: none; margin-left: 15px;">
  <svg version="1.1" fill="currentColor"
    viewBox="0 0 106.7 106.7" style="enable-background:new 0 0 100 100; width: 48px;"
     xml:space="preserve">
    <g style="scale: 1; transform: translate(35px, 24px);transform-origin: 50% 50%">
    <path d="M26.3,0.4C25.5,0.3,22.6,0,19.4,0C12.5,0,7.8,4.2,7.8,11.9v6.6H0v9h7.8v23.1h9.3v-23h7.7l1.2-9h-8.9v-5.8
    c0-2.6,0.7-4.4,4.5-4.4h4.8L26.3,0.4L26.3,0.4z"></path>
    </g>
  </svg>
</a>
<a href="javascript:void;" data-w="in" style="text-decoration: none; margin-left: 5px;">
  <svg version="1.1" fill="currentColor" viewBox="0 0 100 100" 
    style="enable-background:new 0 0 100 100; width: 48px;" xml:space="preserve">
    <g style="scale: .95; transform: translate(26px, 24px); transform-origin: 50% 50%">
       <path d="M46.8,27.4c0-9.3-4.9-13.6-11.5-13.6c-5.4,0-7.8,3-9.1,5.1h0.1v-4.4h-10c0,0,0.1,2.8,0,30.2h10V27.9c0-0.9,0.1-1.8,0.3-2.5
      c0.7-1.8,2.4-3.7,5.2-3.7c3.6,0,5.1,2.8,5.1,6.8v16.2h10L46.8,27.4L46.8,27.4z"></path>
      <path d="M11.3,5.2c0-3-2.2-5.2-5.6-5.2C2.3,0,0,2.3,0,5.2s2.2,5.2,5.5,5.2h0.1C9.1,10.4,11.3,8.1,11.3,5.2"></path>
      <rect x="0.6" y="14.5" width="10.1" height="30.2"></rect>
    </g>
  </svg>
</a>
<a href="javascript:void;" data-w="tw" style="text-decoration: none; margin-left: 10px;">
  <svg version="1.1" fill="currentColor" viewBox="0 0 100 100" xml:space="preserve" style="width: 48px;">
    <g style="scale: 1.26; transform: translate(30px, 34px); transform-origin: 50% 50%">
      <circle cx="32.776" cy="10.165" r="2.513"/>
      <path d="M39.703,3.933c-1.266,0.573-2.666,0.92-4.032,1.149c0.648-0.109,1.6-1.283,1.982-1.757c0.583-0.724,1.066-1.549,1.338-2.44
        c0.035-0.07,0.06-0.157-0.009-0.21c-0.087-0.035-0.144-0.017-0.214,0.018c-1.524,0.817-3.102,1.41-4.78,1.829
        c-0.139,0.035-0.242,0-0.33-0.087c-0.139-0.157-0.27-0.305-0.418-0.427c-0.705-0.6-1.462-1.071-2.316-1.419
        c-1.121-0.457-2.345-0.65-3.551-0.572c-1.174,0.076-2.327,0.407-3.369,0.952c-1.044,0.546-1.976,1.305-2.718,2.22
        c-0.767,0.947-1.334,2.066-1.623,3.251c-0.28,1.144-0.257,2.276-0.086,3.433c0.026,0.192,0.009,0.218-0.165,0.192
        C12.848,9.087,7.405,6.738,2.988,1.704C2.796,1.485,2.692,1.485,2.535,1.72c-1.933,2.898-0.995,7.553,1.42,9.841
        c0.322,0.305,0.653,0.61,1.009,0.888c-0.13,0.027-1.737-0.148-3.169-0.888c-0.192-0.121-0.288-0.052-0.305,0.166
        c-0.018,0.313,0.009,0.601,0.052,0.941c0.371,2.937,2.405,5.654,5.191,6.715c0.331,0.139,0.697,0.261,1.053,0.322
        c-0.635,0.139-1.288,0.239-3.109,0.096c-0.227-0.044-0.313,0.07-0.227,0.287c1.368,3.728,4.321,4.838,6.532,5.469
        c0.296,0.052,0.592,0.052,0.889,0.121c-0.018,0.027-0.036,0.027-0.053,0.053c-0.734,1.118-3.276,1.95-4.46,2.364
        c-2.154,0.753-4.499,1.091-6.775,0.867c-0.364-0.054-0.441-0.05-0.54,0c-0.1,0.062-0.013,0.149,0.104,0.243
        c0.462,0.305,0.932,0.575,1.411,0.837c1.445,0.766,2.953,1.376,4.528,1.811c8.138,2.248,17.305,0.596,23.416-5.475
        c4.799-4.768,6.48-11.343,6.48-17.926c0-0.258,0.304-0.4,0.485-0.536c1.249-0.938,2.255-2.061,3.187-3.314
        c0.209-0.282,0.211-0.532,0.211-0.635c0-0.018,0-0.035,0-0.035C39.867,3.828,39.865,3.859,39.703,3.933z"/>
    </g>
  </svg>
</a>
`;

  CI.dom.social_icons = share_btn
    .append("span")
    .attr("class", "social_icons")
    .style("position", "absolute")
    .style("width", "100%")
    .style("top", "0")
    .style("left", "0")
    .style("opacity", 0)
    .html(icons)

  CI.dom.social_icons
    .selectAll("a")
    .style("color", "#fff");

  const hover = {
    is_active: false,
    update(active) {
      setTimeout(() => (this.is_active = active), 1);
      share_btn
        .select(".share_text")
        .transition()
        .style("opacity", +!active);
      share_btn
        .select(".social_icons")
        .transition()
        .style("opacity", +!!active);
    },
    maybe(fn) {
      const ifRun = cond => fn => arg => (cond() && fn(arg)) || null;

      return ifRun(() => this.is_active)(fn);
    }
  };
  // hover(true);
  share_btn.on("mouseenter", () => hover.update(true));
  share_btn.on("mouseleave", () => hover.update(false));

  function popupCenter(url, title, win, w, h) {
    const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
    const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
    return win.open(
      url,
      title,
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      y +
      ", left=" +
      x
    );
  }

  let source_url = window.location.href.replace(
    "http://localhost:8080",
    "development.terraadriatica.hr"
  );

  const pipeProm = (...funs) => {
    const Promisefy = (fn, v) => Promise.resolve(fn(v));
    return async function(v) {
      let acc = v;
      for (let f of funs) {
        acc = await Promisefy(f, acc);
      }
      return acc;
    };
  };


  {
    const clickHandler = () => {
      const meta_desc = document.querySelector(
        "meta[name='og:description']"
      );
      const text = meta_desc ? meta_desc.content : "";
      let url =
        "https://www.facebook.com/sharer/sharer.php?" +
        "u=" +
        source_url +
        "&quote=" +
        encodeURIComponent(CI.meta_tags.description_soc);
      popupCenter(url, "", window, 560, 600);
    };

    share_btn
      .select("[data-w='fb']")
      .on("click", hover.maybe(pipeProm(getMeta, clickHandler)));
  }

  {
    const clickHandler = () => {
      let url =
        "https://www.linkedin.com/shareArticle?mini=true&url=" + source_url;
      popupCenter(url, "", window, 570, 610);
    };

    share_btn
      .select("[data-w='in']")
      .on("click", hover.maybe(pipeProm(getMeta, clickHandler)));
  }

  {
    const clickHandler = () => {
      let url =
        "https://twitter.com/share?url=" +
        source_url +
        "&text=" +
        encodeURIComponent(CI.meta_tags.description_soc);
      popupCenter(url, "", window, 570, 610);
    };

    share_btn
      .select("[data-w='tw']")
      .on("click", hover.maybe(pipeProm(getMeta, clickHandler)));
  }

  return CI
}

export default ShareBtn;
